import './candidates.css'

function Candidates() {
    return(
        <>
        
        </>
    )
}

export default Candidates