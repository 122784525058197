export const lang = {
    en: {
        translation: {
            header: {
                lang : "LANG",
                login : "LOGIN",
                reg : "REGISTRATION",
                prof : "PROFILE",
                link1 : "Home",
                link2 : "Platform",
                link3 : "Investment Services",
                link4: "Clients",
                link5 : "Resources",
                link6 : "Join Capital",
                link7 : "Training packages",
                h_button : "OPEN ACCOUNT",
                link3item1 : "Annuities",
                link3item2 : "Bank Investment Services",
                link3item3 : "Certificates of Deposits",
                link3item4 : "Corporate Bonds",
                link3item5 : "Corporate Finance",
                link3item6 : "Estate Planning",
                link3item7 : "Government Securities",
                link3item8 : "Insurance",
                link3item9 : "Investment Banking",
                link3item10 : "Limited Partnerships",
                link3item11 : "Money Management",
                link3item12 : "Money Market Funds",
                link3item13 : "Mortgages",
                link3item14 : "Municipal Bonds",
                link3item15 : "Mutual Funds",
                link3item16 : "Options",
                link3item17 : "Portfolio Review",
                link3item18 : "Documents",
                link4item1 : "DOL Transition Disclosure",

            },
            homePage : {
                title: "INTEGRITY INDEPENDENTS INNOVATION",
                but1 : "JOIN US",
                but2 : "REASONS",
                subt : "Over 110.000 users",
                text1 : " Over the last three decades, Hill House Capital Companies has built a loyal following through our independence and self-determination. By avoiding the merger/acquisition lure that flooded the financial services industry over the last several years, our company has remained solid and strong, growing with our clients' needs while never outgrowing our own capabilities. Through our Ensemble Platform, we offer a team of professionals with comprehensive services, working together as one, for you...",
                text2 : "JOIN TO PLATFORM",
                headcard1 : "Integrity",
                headcard2 : "Independence",
                headcard3 : "Innovation",
                textcard1 : "The core principle that guides our firm. We pledge to provide you with an investment experience based on honesty, transparency and high ethical standards. Integrity is of such importance that without it, the remaining core values are meaningless.",
                textcard2 : "We have established a culture that promotes freedom to provide the best possible investment solution for our clients. Though fiercely independent, we pride ourselves on being interdependent when it comes to sharing strategies that benefit everyone.",
                textcard3 : "In the rapidly changing investment world, we strive to create new processes and services based on leading edge technologies. Our goal is to continue to implement new methods and processes rather than relying on strategies that worked in the past thus initiating positive change for you.",
                crs : "Form CRS (Customer Relationship Summary) for our entities",
                givtext1 : "Global New Year's raffle among ",
                givtext2 : "existing ",
                givtext3 : "clients",
                givtext4 : "Join HILLHOUSE today and become a member",
                givtext5 : " Become a Member",
                givtext6 : "Draw results ",
                givtext7 : "December",
                givtext8 : "winners will be notified by email",
            },
            registration : {
                but12 : "Registration",
                home : "Home",
                login : "Login",
                show : "Show password",
                read : "I have read the ",
                read2 : "and agree to the terms",
                error1 : "Please complete all required fields!",
                error2 : "The password must be at least 8 characters long and must contain uppercase and lowercase Latin letters.",
            },
            login : {
                button1 : "Login",
                home : "Home",
                register : "Register",
                error1 : "Please complete all required fields!",
                error2 : "Password length is less than 8 characters or does not contain a number",
                forgot : "Forgot password",
                change_password: "Change",
                password__error: "wrong mail",
                password__good: "Password changed successful",
                something__wrong: "Ooops something went wrong"
            },
            prof : {
                title : "OVER 110 000 ACTIVE USERS NOW",
                button : "Withdraw",
                payment1 : "BillingСX",
                payment2 : "Coinumm",
                button2 : "OPEN PLATFORM",
                subtitle : "Activate your account today and get a free trading guide",
            },

            // new
            footer :{
                contacts : "Contact",
            },
            capitalInvestment : {
                title : "PRODUCTS AND SERVICES OFFERED THROUGH THE CAPITAL INVESTMENT COMPANIES:",
                list1 : "Common Stocks",
                list2 : "Preferred Stocks",
                list3 : "Corporate Bonds",
                list4 : "Options",
                list5 : "Unit Investment Trusts",
                list6 : "Mutual Funds",
                list7 : "Money Market Funds",
                list8 : "Government Securities",
                list9 : "Annuities",
                list10 : "Limited Partnerships",
                list11 : "Retirement Plans",
                list12 : "Certificates of Deposit",
                list13 : "Investment Advisory Services",
                list14 : "Portfolio Review",
                list15 : "Estate Planning",
                list16 : "Insurance",
                list17 : "Corporate Finance",
            },
            mortage : {
                sub : "Capital Mortgage Associates can assist with residential and commercial lending and refinancing",
                text1 : "Who would know better how to find the most financially responsible and rewarding mortgage loan than a Capital financial consultant?",
                text2 : "The most significant investment decision you will probably ever make is the financing of your home. The cost of financing your home and its impact on your total financial plan is significant. If you are ready to buy a first or second home, or are considering refinancing, our financial consultants can help guide you through the home financing process.",
                text3 : "A Capital consultant can help ensure that your home financing furthers the financial goals you've set by planning your next mortgage and possibly assisting in an equity line strategy. It makes sense to let a financial expert work for you.",
                text4 : "Capital Mortgage Associates has access to many national lenders with extremely competitive rates and programs",
            },
            investmentAdvisory : {
                sub : "Capital Investment Advisory Services, LLC provides a platform for those investment advisory representatives who choose to manage their own client accounts.",
                text1 : "HCIAS was created around the vision that independent financial advisors are best served when they are empowered with the capability to seamlessly integrate fee and commission-based services for their end clients.",
                text2 : "HCIAS attracts investment advisors who, if they chose to establish their own registered investment advisory firm, would normally have to deal with the complexities of multiple state registrations or those who do not have the desire to dedicate staff and resources to RIA maintenance. The association with Capital Investment Group, Inc, the broker/dealer, also gives our financial professionals the ability to offer products and services in a true hybrid business model serving clients on either a fee or commission basis.",
                text3 : "HCIAS advisors may pursue their own individual business models while offloading growing regulatory complexity and costs to their back office.",
                text4 : "We offer our IARs the maximum flexibility of multiple custodial partners typically associated only with RIA firm ownership. We make available a comprehensive platform of products, trading and technology systems, as well as customized wealth management and practice management solutions.",
                text5 : "Additionally, HCIAS offers top-of-the-line resources such as portfolio management and performance reporting technology, as well as professionally developed mutual fund and ETF models for those advisors who choose to focus their efforts on meeting the needs of their clients instead of continually staying on top of the analytics of account management.",
                text6 : "HCIAS advisors can leverage our extensive experience in the hybrid space, and take comfort in knowing we manage the significant challenges of compliance and regulatory changes.",
            },
            affiliates : {
                sub : " Capital Insurance Affiliates is a full service life insurance general agency and estate planning affiliate, which offers a vast array of life insurance and annuity carriers.",
                text1 : "Whether it is low-cost term insurance or permanent protection, Capital Insurance Affiliates can provide the most competitive rates from some of the most financially sound insurance companies available.",
                text2 : "Our estate and business analysis team can assist with plans to help business owners maintain their competitive advantage through \"golden handcuff\" arrangements, qualified and non-qualified plans, key person insurance and business succession planning.",
                text3 : "Capital Insurance Affiliates is also available for those who wish to minimize their estate taxes. We can assist in reducing the estate tax burden by establishing the need for irrevocable life insurance trusts, qualified personal residence trusts, family limited partnerships and charitable remainder trusts.",
                text4 : "From complex estate planning and tax reduction techniques to competitive insurance and annuity products, to life insurance audits, Capital Insurance Affiliates can help you navigate critical insurance issues with your best interests in mind.",
            },
            counsel : {
                sub : "Capital Investment Counsel is a money management firm dedicated to serving the specific needs of its clients through individually tailored portfolios positioned in the equity and fixed-income markets. The foundation of our firm is knowledge, integrity and experience. Patience and discipline are the key components of our investment programs.",
                title1 : "Our Purpose",
                text1 : "Capital Investment Companies brings together an experienced group of professionals to implement our contrarian style of investing.  Our approach focuses on buying financially-strong companies when they are out of favor and near historically-low valuations.  We acquire individual positions in publicly-traded companies of all market capitalizations, while focusing on a disciplined process designed to manage risk for the investor while seeking above-average returns over time.  We complement our core equity approach with ETF exposure in areas where we prefer broad exposure.  Our portfolios are all-market cap and global in nature and are intended to be a complete portfolio for the investor.",
                title2 : "Why We Are Different",
                text2 : "Because we believe managing money is personal, all portfolios are individually tailored to meet a client's specific needs, tolerance for risk and tax situation. Two key components of our investment program are disciplined investment processes and patience. Our practice of considering companies of all market capitalizations eliminates the need for numerous sector investments. Our investment discipline is designed for the long-term investor. Short-term trends or market volatility do not alter our philosophy or decision-making process. We do not try to predict the market, but rather strive to take advantage of market conditions. Existing low cost basis holdings can be incorporated into one account. These positions will be taken into consideration for tax consequences, asset allocation and management fees when managing your portfolio.",
                title3 : "Risk Management Process",
                text3 : "We manage risk for our clients in the equity and fixed-income markets by acquiring positions in publicly-traded companies of all market capitalizations, while implementing a discipline to control the three primary areas of risk for the investor.",
                spantitle1 : "Price Risk:",
                spantext1 : "The purchase of a security that exceeds a fair-market value based on underlying and accepted measures of valuation. This is commonly known as investing in trends or momentum investing.",
                spantitle2 : "Credit Risk:",
                spantext2 : "The lack of attention to financial detail that could determine a company's ability to successfully work through difficult economic or business cycles. The focus should be on low-debt companies with strong balance sheets.",
                spantitle3 : "Time Risk:",
                spantext3 : "The lack of patience that would typically allow an investment discipline to achieve its purpose. Most investors don't give their decisions proper time to work. We may complement our stock selections with investment-grade bond holdings, in accordance with the investment objectives of the portfolio. We manage four types of portfolios for investors: growth, growth and income, balanced and income. To achieve proper diversification, it is felt a client needs to invest a minimum of $100,000.",
                sub1 : "Our Typical Client Profile",
                sub2 : "Our typical client recognizes the value of professional service. They do not have the time, expertise or desire to manage their investment portfolio; therefore they seek out customized services with someone they can trust.",
                sub3 : "What a Client Receives",
                sub4 : "An experienced money management team with individualized account management",
                sub5 : "Full disclosure of all fees and expenses",
                sub6 : "Tax efficiency through proactive management of gains and losses",
                sub7 : "Quarterly and annual reporting in a simple, straightforward format",
                sub8 : "Disclosures: *As of 01/01/2017**Information available upon request. Past performance is not indicative of future results. This material is not financial advice or an offer to sell any product. The actual characteristicswith respect to any particular client account will vary based on a number of factors, including, but not limited to: the size of the account;investment restrictions applicable to the account, if any; and market exigencies at the time of investment. Capital Investment Counselreserves the right to modify its current investment strategies and techniques based on changing market dynamics or client needs.Capital Investment Counsel is a registered investment advisor.",

            },
            administrators : {
                sub : "Capital's Retirement Plan Services provides creative concepts in retirement planning and complete employee benefit administration to all employers.",
                text : "Today, retirement plans are an integral part of the total compensations picture of any business. Their cost, complexities, and variations have created a demand for specialists to design and install them. To develop such programs around the deferment of income on a tax-advantaged basis, we offer. The experience of a professional staff focused on constant changes in tax law, investments, and the needs of the employer and employees.",
                title : "Our Services Include:",
                text1 : "Design and Compliance.",
                text11 : "We examine the scope of all types of plans available and coordinate with management, legal and tax advisors in choosing the best plan or combination of plans available.",
                text2 : "Qualification and Installation.",
                text22 : "We provide all necessary plan documents and descriptions through our own nationally approved prototypes.",
                text3 : "Employee Communication.",
                text33 : "Our consultants are trained to properly enroll and educate your employees about the retirement program.",
                text4 : "Funding.",
                text44 : "We provide analysis and recommendation of all investment products through our affiliated Capital Investment Companies",
                text5 : "Annual Plan Administration.",
                text55 : "We provide comprehensive administrative services designed to minimize the time and cost burden on the company and its employees.",
                types : "Types of Plans:",
                item1 : "401(k) Cash or Deferred Plans",
                item2 : "Age Based Profit Sharing Plans",
                item3 : "Target Benefit Plans",
                item4 : "Simple Plans",
                item5 : "Profit Sharing Plans",
                item6 : "Money Purchase Pension Plans",
                item7 : "Comparable Plans",
                item8 : "Safe Harbor Plans",
                textb : "The mission of Capital's Retirement Plan Services is to design and manage a retirement program responsive to your needs and goals with quality service, great investment returns, and effective cost.",
            },
            trustservice : {
                sub : " Selecting an appropriate trust company or trustee is not an easy task. At Capital Investment Companies (CIC), we understand the importance of providing complete fiduciary services from a single source, while not compromising the service or investment advice we offer our clients. We use Pershing's Trust Network to service our client's Trust needs.",
                title : "FIRST CAROLINA WEALTH",
                text1 : "First Carolina Bank is proud to offer Wealth Management services through our strategic partner Capital Investment Companies (CIC). CIC, through it's broker-dealer and investment advisory firms, is able to offer our customers a distinctive management philosophy by creating individual solutions designed to meet your specific financials goals. You will have access to a diverse selection of investment and financial services for every goal and stage of your life.",
                title1 : "PERSHING'S TRUST NETWORK",
                text2 : "Pershing's Trust Network was established to meet the individual and institutional demands for higher levels of personal service and to provide a broad selection of high-quality investments in a fiduciary environment. CIC concentrates its resources on its core business -- meeting your investment needs.",
                text3 : "Whether you are seeking fiduciary services for your company or for yourself, we take the time to help you understand the many complex issues and regulations, to work with you in planning strategically, to look at all the options, and to help you make thoughtful but sometimes difficult decisions. Our team can also provide creative solutions to your company's employee benefit needs.",
                text4 : "The delivery of quality service starts with people, but it must also include proven operational capabilities. Pershing's Trust Network has the experienced personnel as well as the sophisticated technology needed to manage your fiduciary affairs in a prudent and timely manner.",
                text5 : "Many high-net-worth clients want to leave a meaningful legacy to future generations and charitable organizations. To do this, they may establish trusts to help them transfer wealth efficiently, ensure that their wishes are carried out, minimize estate taxes and avoid probate.",
                text6 : "Whether Pershing clients are establishing a trust, or are the benficiary of a trust, through Pershing's Trust Network, our clients have access to trustee services from some of the nation's most highly regarded trust companies. Clients can access a full menu of solution that seek to meet virtually any personal trust need, while relying on Capital to manage their trsut assets.",
                title2 : "CIC with Pershing's Trust Network offer you these advantages:",
                item1 : "An experienced team with proven success",
                item2 : "Personal and objective investment guidance",
                item3 : "Customized administrative services",
                item4 : "An unlimited menu of investment options",
                title3 : "The following service features help us achieve this goal:",
                item5 : "A personal investment strategy built around each client;",
                item6 : "Broad diversification by asset class and style;",
                item7 : "Access to some of the world's most talented money managers;",
                item8 : "On-going portfolio monitoring;",
                item9 : "Detailed account statements and performance reports;",
                item10 : "Consistent client communication and technological support",
                textb1 : "CIC, along with Pershing's Trust Network, offers you a unique duo of professional objectivity, flexibility, expertise and integrity unmatched in today's financial marketplace.",
                textb2 : "Let CIC and Pershing's Trust Network successfully assist you in achieving your long-term financial goals and objectives.",

            },
            capitalbankpartners : {
                sub : "Capital Bank Partners offers full-service investment programs to Community Banks and their Customers.",
                title1 : "Banking and Investments: A powerful combination",
                title2 : "Program Delivery and Management.",
                text1 : "Since 1984, Capital Investment Group, Inc. has provided a wide array of investment products and services to businesses and individuals alike. Our investment services programs for community banks enable banking institutions to effectively \"bridge the gap \" between banking and investments for bank customers. Meeting all customers' financial needs in one place offers two important benefits. First, it provides an easy way for customers to consolidate their banking and investment needs with an institution they trust. Second, it allows banks to enhance and expand customer relationships while increasing fee income, cross-sell ratios and employee marketing skills.",
                text2 : "At Capital, we understand the demands placed on your management and employees by today's fast-paced and ever-changing banking environment. With that in mind, we offer a turn-key investment services program that assists you from the initial structure of the program to the recruiting and sales management of your investment representative. We are committed to providing the most comprehensive and detailed support in the industry. Your investment representative will be able to offer your customers a selection of investment products and services second to none, drawing upon state-of-the-art technology for account access and management. Our goal is to provide you with an investment services program that is competitive with other banks, brokers and financial services providers. As a result, your customers will no longer need to look to other institutions for help in meeting their financial needs.",
            },
            documents : {
                but : "OPEN",
                title : "DOCUMENTS",
            },
            resources : {
                title : "TOOLS AND LINKS TO HELP YOU!",
                title1 : "Calculators:",
                title2 : "Other Links:",
                link1 : "A Comprehensive List of over 50 Useful Calculators",
                link2 : "72 (t) / Retirement Account Withdrawal",
                link3 : "529 Expense Analyzer",
                link4 : "Education Cost Calculator",
                link5 : "Emergency Fund Calculator",
                link6 : "Financial Websites",
                link7 : "Financial Services Institute",
                link8 : "Mortgage Calculator",
                link9 : "Mutual Fund Breakpoint Tool",
                link10 : "Mutual Fund Breakpoint Search Tool",
                link11 : "Mutual Fund and ETF Expense Analyzer",
                link12 : "Mutual Fund Expense Analyzer",
                link13 : "Mutual Fund Expense Calculator",
                link14 : "Options Calculator",
                link15 : "Roth Calculator",
                link16 : "Savings Calculator",
                link17 : "Variable Annuity Calculator",
                link18 : "Financial Glossary",
                descr1 : "Calculates your future cost of education and how much you need to save now to meet that cost.",
                descr2 : "Calculates how much you should save to be prepared for life's little surprises.",
                descr3 : "Calculates your monthly payment or loan amount and displays an annualization table.",
                descr4 : "Calculates how much you need to save now to retire a millionaire.",
                descr5 : "Over 6,000 entries with 15,000 links compiled by Duke University Professor Campbell R. Harvey.",

            },
            joinCapital : {
                title : "JOIN OUR TEAM",
                but1 : "Join",
                but2 : "Reasons",
                p1 : "Capital Investment Companies is one of the premier independent broker-dealers in the SouthEast. We offer all of the resources of a large, corporate-backed firm to support our independent reps, but it's our personalized support that gives us that \"family feel\". This commitment to personal care has earned our company a loyal team of brokers and a following of investors that's growing every day.",
                p2 : "With the latest technology, ample wealth management solutions, competitive fees, broker friendly compliance, we offer everything from traditional brokerage to money management to estate planning to trust services to comprehensive insurance solutions  to compliment a holistic style of financial planning. Our hands-on, in the field assistance makes us your \"team for success\".",
                p3 : "If you are looking for a place you can soar past corporate red tape to individual success -- as an independent rep or an investor -- ",
                link : "Come Home To Capital!",
                em : "then you should",
            },
            courses : {
                title : "Our packages",
                item1 : "electronic books",
                item2 : "educational materials",
                item3 : "advanced charting software",
                item4 : "market news",
                item5 : "video lessons",
                item6 : "daily trading signals",
                item7 : "economic calendar",
                no : "no consultations",
                yes : "consultation included",
                button : "Get",
            },
            reasons : {
                title : "REASONS",
                sub : "REASONS WHY YOU SHOULD PICK CAPITAL INVESTMENT COMPANIES FOR YOUR BROKER/DEALER....",
                list1 : "High commission payouts with minimum break points.",
                list2 : "Comprehensive, reasonably priced, E & O insurance with a Deductible Reinsurance Policy.",
                list3 : "Comprehensive insurance and estate planning services with high payouts.",
                list4 : "Efficient, fast, trading and clearing services provided by Pershing.",
                list5 : "Professional, courteous home office staff resulting in minimal problems and quick solutions.",
                list6 : "You determine customer commission rates, from discount to full-service.",
                list7 : "Complete  fixed income trading services through Pershing.",
                list8 : "$100 million of customer brokerage account protection with Pershing. ",
                list9 : "Cash Management Accounts (CMA's) and FREE checking services at Pershing..",
                list10 : "Monthly sales calls concentrating on sales and marketing.",
                list11 : "Revitalizing national sales conferences with Continuing Education.",
                list12 : "\"In-the-field\" sales training and assistance.",
                list13 : "Assistance with practice management.",
                list14 : "In-house professional due diligence supplemented by outside counsel.",
                list15 : "Comprehensive Alternative Investment Platform",
                list16 : "Home or branch office training classes for your support staff.",
                list17 : "Fast service on your securities license transfer if eligible for \"TAT\".",
                list18 : "48-hour turn-around on compliance approval for seminars, radio shows, articles.",
                list19 : "24-hour compliance approval on business cards, stationary, and signs.",
                list20: "Comprehensive 'Best Practices' Technology Platform featuring portfolio monitoring, risk or asset allocation reports, practice management, financial planning, portfolio performance and aggregation reports with Albridge.",
                list21 : "Money Management programs offering various nationally recognized managers.",
                list22 : "The Ensemble Platform which is comprised of seven different companies/divisions to cross-market and grow your business.",
                // list2
                list23 : "Capital Insurance Affiliates - a full service insurance division with estate planning expertise.",
                list24 : "Capital Advisers - an asset allocation program using no-load mutual funds, load funds at NAV, ETF's and Separate Account Managers.",
                list25 : "Capital Mortgage Associates - helps your clients finance or refinance their homes.",
                list26 : "The Capital Foundation - an organization that addresses individual charitable concerns while enjoying financial advantages of tax-exempt status.",
                list27 : "Capital Investment Advisory Services - Comprehensive \"rep-as-manager\" platform with low-priced models to meet your clients' needs.",
                list28 : "Capital's Trust Services - fiduciary services from a single source to meet any of your clients' estate planning needs.",
                list29 : "Capital Bank Partners - we help \"bridge-the-gap\" between banking and investments for our banks' customers.",

            },
            compliance : {
                title : "Client Relationship Summaries for Our Entities",
                sub : "Client Relationship Summaries for Our Entities (Form CRS)",
                bussines : "Business Continuity Notice",
                text1 : "In the event of an internal or external significant business disruption, our firm has developed a business continuity plan designed to how we will respond to events that significantly disrupt our business. For your convenience, we have included a copy of our Notice of Business Continuity Plan.",
                text2 : "SEC Rule 11AC1-6 The SEC has adopted rules to improve public disclosure of order execution and routing practices. Under Rule 11AC1-6, Capital Investment Group, Inc. and Capital Investment Brokerage, Inc. are required to make publicly available quarterly reports that, among other things, identify the venues to which customer orders are routed for execution. In addition, Capital Investment Group, Inc. and Capital Investment Brokerage, Inc. are required to disclose to customers, upon written request, the venues to which their individual orders were routed. By making visible the execution quality of the securities markets, the rules are intended to spur more vigorous competition among market participants to provide the best possible prices for investor orders.",
                text3 : "Capital Investment Group, Inc. and Capital Investment Brokerage, Inc. routes all eligible OTC and Listed orders directly to our clearing firm, Pershing LLC, for execution.",
                text4 : "To obtain statistical information on order routing, click on the Search tab at the top of the page on the following site,",
                text5 : "The Securities and Exchange Commission requires all brokerage firms to inform their client's as to whether such firms receive payment for order flow. Capital Investment Group, Inc. and Capital Investment Brokerage, Inc., do not receive such payment. Order flow refers to the process by which your orders are executed. Seeking to execute a client's order into the best available market, a brokerage firm may execute the order as principal, or may route the order to an affiliated or non-affiliated broker-dealer or exchange specialist for execution",
                title1 : "Order Routing",
                title2 : "Payment for Order Flow",
            }
            
        }
    },
    ru: {
        translation: {
            header : {
                lang : "ЯЗЫК",
                login : "ВХОД",
                reg : "РЕГИСТРАЦИЯ",
                prof : "ПРОФИЛЬ",
                link1 : "Главная",
                link2 : "Инвестиционные услуги",
                link3 : "Платформа",
                link4 : "Клиентам",
                link5: "Ресурсы",
                link6 : "Присоедениться к нам",
                link7 : "Пакеты обучения",
                h_button : "Открыть аккаунт",
                link3item1 : "Аннуитеты",
                link3item2 : "Банковские инвестиционные услуги",
                link3item3 : "Депозитные сертификаты",
                link3item4 : "Корпоративные облигации",
                link3item5 : "Корпоративные финансы",
                link3item6 : "Планирование недвижимости",
                link3item7 : "Государственные ценные бумаги",
                link3item8 : "Страхование",
                link3item9 : "Инвестиционная деятельность банков",
                link3item10 : "Товарищества с ограниченной ответственностью",
                link3item11 : "Управление деньгами",
                link3item12 : "Фонды денежного рынка",
                link3item13 : "Ипотека",
                link3item14 : "Муниципальные облигации",
                link3item15 : "Паевые инвестиционные фонды",
                link3item16 : "Опционы",
                link3item17 : "Обзор портфолио",
                link3item18 : "Документы",
                link4item1 : "Раскрытие информации о переходе DOL",
            },
            homePage : {
                title: "ЧЕСТНОСТЬ НЕЗАВИСИМОСТЬ ИННОВАЦИИ",
                but1 : "Присоедениться",
                but2 : "Почему мы?",
                subt : "Более 110.000 пользователей",
                text1 : "За последние три десятилетия Hill House Capital Company приобрела верных последователей благодаря нашей независимости и самоопределению. Избегая приманки слияний/поглощений, которая захлестнула индустрию финансовых услуг за последние несколько лет, наша компания осталась солидной и сильной, развиваясь вместе с потребностями наших клиентов, но никогда не превышая собственные возможности. С помощью нашей платформы Ensemble мы предлагаем команду профессионалов с комплексными услугами, которые работают вместе, как единое целое, для вас...",
                text2 : "ПРИСОЕДИНИТЬСЯ К ПЛАТФОРМЕ",
                headcard1 : "Честность",
                headcard2 : "Независимость",
                headcard3 : "Инновации",
                textcard1 : "Основной принцип, которым руководствуется наша фирма. Мы обязуемся предоставить вам инвестиционный опыт, основанный на честности, прозрачности и высоких этических стандартах. Целостность настолько важна, что без нее остальные основные ценности теряют смысл.",
                textcard2 : "Мы создали культуру, которая способствует свободе предоставления наилучших возможных инвестиционных решений для наших клиентов. Несмотря на крайнюю независимость, мы гордимся тем, что взаимозависимы, когда речь идет об обмене стратегиями, которые приносят пользу всем.",
                textcard3 : "В быстро меняющемся инвестиционном мире мы стремимся создавать новые процессы и услуги на основе передовых технологий. Наша цель — продолжать внедрять новые методы и процессы, а не полагаться на стратегии, которые работали в прошлом, тем самым инициируя положительные изменения для вас.",
                crs : "Форма CRS (Сводка взаимоотношений с клиентами) для нашей организации",
                givtext1 : "Глобальный новогодний розыгрыш среди ",
                givtext2 : "действующих",
                givtext3 : " клиентов",
                givtext4 : "Присоеденяйся к компании HILLHOUSE сегодня и становись участником",
                givtext5 : " Стать участником",
                givtext6 : "Результаты розыгрыша ",
                givtext7 : "декабря",
                givtext8 : "победители будут оповещены на почту",
            },
            registration : {
                but12 : "Регистрация",
                home : "Главная",
                login : "Авторизоваться",
                show : "Показать пароль",
                read : "я прочитал",
                read2 : "и соглашаюсь с условиями",
                error1 : "Пожалуйста, заполните все необходимые поля!",
                error2 : "Пароль должен состоять не менее чем из 8 символов и содержать заглавные и строчные латинские буквы.",
            },
            login : {
                button1 : "Войти",
                home : "Главная",
                register : "Зарегестрироваться",
                error1 : "Please complete all required fields!",
                error2 : "Password must be 8 characters or more",
                forgot : "Забыл пароль",
                change_password: "Изменить",
                password__error: "Неправильный эмеил",
                password__good: "Пароль изменен!",
                something__wrong: "Упс что-то пошло не так :("
            },
            prof : {
                title : "БОЛЕЕ 110 000 АКТИВНЫХ ПОЛЬЗОВАТЕЛЕЙ",
                button : "Вывод средств",
                payment1 : "BillingСX",
                payment2 : "Coinumm",
                button2 : "Открыть платформу",
                subtitle : "Активируйте свою учетную запись сегодня и получите бесплатное руководство по торговле",
            },

            // new
            footer :{
                contacts : "Contact",
            },
            capitalInvestment : {
                title : "ПРОДУКТЫ И УСЛУГИ ПРЕДЛАГАЕМЫЕ КОМПАНИЕЙ HILLHOUSE CAPITAL:",
                list1 : "Обыкновенные акции",
                list2 : "Привилегированные акции",
                list3 : "Корпоративные облигации",
                list4 : "Oпционы",
                list5 : "Паевые инвестиционные фонды",
                list6 : "Паевые фонды",
                list7 : "Фонды денежного рынка",
                list8 : "Государственные ценные бумаги",
                list9 : "Аннуитеты",
                list10 : "Товарищества с ограниченной ответственностью",
                list11 : "Пенсионные планы",
                list12 : "Депозитные сертификаты",
                list13 : "Инвестиционные консультационные услуги",
                list14 : "Обзор портфолио",
                list15 : "Планирование недвижимости",
                list16 : "Страхование",
                list17 : "Корпоративные финансы",
            },
            mortage : {
                sub : "Hillhouse Capital Mortgage Associates может помочь с жилищным и коммерческим кредитованием и рефинансированием.",
                text1 : "Кто лучше знает, как найти наиболее финансово ответственный и выгодный ипотечный кредит, чем финансовый консультант Hillhouse?",
                text2 : "Наиболее важное инвестиционное решение, которое вы, вероятно, когда-либо примете, — это финансирование вашего дома. Стоимость финансирования вашего дома и ее влияние на ваш общий финансовый план значительны. Если вы готовы купить первый или второй дом или рассматриваете возможность рефинансирования, наши финансовые консультанты могут помочь вам в процессе финансирования дома.",
                text3 : "Консультант Hillhouse может гарантировать, что ваше финансирование дома способствует достижению финансовых целей, которые вы установили, планируя следующую ипотеку и возможно, помогая в стратегии в приобреении акций. Имеет смысл позволить финансовому эксперту работать на вас.",
                text4 : "Hillhose Capital Mortgage Associates имеет доступ ко многим национальным кредиторам с чрезвычайно конкурентоспособными ставками и программами.",
            },
            investmentAdvisory : {
                sub : "Hillhose Capital Investment Advisory Services, LLC предоставляет платформу для тех представителей инвестиционного консалтинга, которые предпочитают управлять своими собственными клиентскими счетами.",
                text1 : "HCIAS был создан на основе видения, что независимые финансовые консультанты лучше всего обслуживаются, когда они наделены возможностью беспрепятственно интегрировать платные и комиссионные услуги для своих конечных клиентов.",
                text2 : "HCIAS привлекает инвестиционных консультантов, которым, если они решат создать свою собственную зарегистрированную инвестиционную консалтинговую фирму, обычно придется иметь дело со сложностями множественных государственных регистраций, или тех, у кого нет желания выделять персонал и ресурсы для обслуживания RIA. Партнерство с Hillhouse Capital Investment Group, Inc, брокером/дилером, также дает нашим финансовым специалистам возможность предлагать продукты и услуги в настоящей гибридной бизнес-модели, обслуживая клиентов либо на платной, либо на комиссионной основе.",
                text3 : "Консультанты HCIAS могут следовать своим собственным бизнес-моделям, перекладывая растущую сложность регулирования и расходы на свой бэк-офис.",
                text4 : "Мы предлагаем нашим IAR максимальную гибкость нескольких кастодиальных партнеров, обычно связанных только с фирмой RIA. Мы предоставляем комплексную платформу продуктов, торговых и технологических систем, а также индивидуальные решения для управления капиталом и практики.",
                text5 : "Кроме того, HCIAS предлагает первоклассные ресурсы, такие как управление портфелем и технология отчетности об эффективности, а также профессионально разработанные модели взаимных фондов и ETF для тех консультантов, которые предпочитают сосредоточить свои усилия на удовлетворении потребностей своих клиентов, а не на постоянной основе. оставаться в курсе аналитики управления аккаунтом.",
                text6 : "Консультанты HCIAS могут использовать наш обширный опыт в гибридном пространстве и чувствовать себя комфортно, зная, что мы справляемся со значительными проблемами соответствия и нормативных изменений.",
            },
            affiliates : {
                sub : "Hillhouse Capital Insurance Affiliates является генеральным агентством по страхованию жизни с полным спектром услуг и дочерней компанией по планированию недвижимости, которая предлагает широкий спектр страховых компаний и страховых аннуитетов.",
                text1 : "Будь то недорогое срочное страхование или постоянная защита, Hillhouse Capital Insurance Affiliates может предоставить наиболее конкурентоспособные тарифы от некоторых из наиболее финансово надежных страховых компаний.",
                text2 : "Наша группа по анализу недвижимости и бизнеса может помочь с планами, которые помогут владельцам бизнеса сохранить свое конкурентное преимущество с помощью механизмов «золотых наручников», квалифицированных и неквалифицированных планов, страхования ключевых лиц и планирования преемственности бизнеса.",
                text3 : "Hillhouse Capital Insurance Affiliates также доступна для тех, кто хочет минимизировать свои налоги на недвижимость. Мы можем помочь в снижении бремени налога на наследство, установив потребность в безотзывных трастах страхования жизни, квалифицированных трастах личного проживания, семейных товариществах с ограниченной ответственностью и трастах благотворительных остатков.",
                text4 : "От сложного имущественного планирования и методов снижения налогов до конкурентоспособных продуктов страхования и аннуитета до аудитов страхования жизни, Hillhouse Capital Insurance Affiliates может помочь вам решить важные вопросы страхования с учетом ваших интересов.",
            },
            counsel : {
                sub : "Hillhouse Capital Investment Counsel — это компания по управлению капиталом, предназначенная для удовлетворения конкретных потребностей своих клиентов посредством индивидуально подобранных портфелей, позиционируемых на рынках акций и инструментов с фиксированным доходом. Основой нашей фирмы являются знания, добросовестность и опыт. Терпение и дисциплина являются ключевыми компонентами наших инвестиционных программ.",
                title1 : "Наша цель",
                text1 : "Hillhouse Capital Investment Company объединяет группу опытных профессионалов для реализации нашего противоположного стиля инвестирования. Наш подход заключается в покупке финансово устойчивых компаний, когда они не в фаворе и их оценка близка к исторически низкой. Мы приобретаем отдельные позиции в публично торгуемых компаниях любой рыночной капитализации, уделяя особое внимание дисциплинированному процессу, предназначенному для управления рисками для инвестора и стремления к доходности выше среднего с течением времени. Мы дополняем наш подход к основному капиталу доступом к ETF в тех областях, где мы предпочитаем широкий охват. Наши портфели охватывают все рынки и являются глобальными по своему характеру и предназначены для того, чтобы быть полным портфелем для инвестора.",
                title2 : "Почему мы разные",
                text2 : "Поскольку мы считаем, что управление деньгами является личным делом, все портфели составляются индивидуально для удовлетворения конкретных потребностей клиента, терпимости к риску и налоговой ситуации. Двумя ключевыми компонентами нашей инвестиционной программы являются дисциплинированные инвестиционные процессы и терпение. Наша практика рассмотрения компаний с любой рыночной капитализацией устраняет необходимость в многочисленных отраслевых инвестициях. Наша инвестиционная дисциплина предназначена для долгосрочных инвесторов. Краткосрочные тенденции или волатильность рынка не меняют нашу философию или процесс принятия решений. Мы не пытаемся предсказать рынок, а скорее стремимся воспользоваться рыночными условиями. Существующие низкозатратные активы могут быть включены в один аккаунт. Эти позиции будут учитываться в связи с налоговыми последствиями, распределением активов и комиссией за управление при управлении вашим портфелем.",
                title3 : "Процесс управления рисками",
                text3 : "Мы управляем рисками наших клиентов на рынках акций и инструментов с фиксированной доходностью, приобретая позиции в публично торгуемых компаниях любой рыночной капитализации, при этом придерживаясь дисциплины, позволяющей контролировать три основные области риска для инвестора.",
                spantitle1 : "Ценовой риск:",
                spantext1 : "Покупка ценной бумаги, стоимость которой превышает справедливую рыночную стоимость, основанную на базовых и принятых мерах оценки. Это широко известно как инвестирование в тренды или импульсное инвестирование.",
                spantitle2 : "Риск кредита:",
                spantext2 : "Недостаточное внимание к финансовым деталям, которые могут определить способность компании успешно преодолевать сложные экономические или деловые циклы. В центре внимания должны быть компании с низким уровнем долга и сильным балансом.",
                spantitle3 : "Временной риск:",
                spantext3 : "Отсутствие терпения, которое обычно позволяет инвестиционной дисциплине достичь своей цели. Большинство инвесторов не дают своим решениям должного времени для работы. Мы можем дополнить наш выбор акций облигациями инвестиционного класса в соответствии с инвестиционными целями портфеля. Мы управляем четырьмя типами портфелей для инвесторов: рост, рост и доход, сбалансированный и доход. Считается, что для достижения надлежащей диверсификации клиенту необходимо инвестировать минимум 100 000 долларов.",
                sub1 : "Наш типичный клиентский профиль",
                sub2 : "Наш типичный клиент осознает ценность профессионального обслуживания. У них нет времени, опыта или желания управлять своим инвестиционным портфелем; поэтому они ищут индивидуальные услуги у кого-то, кому они могут доверять.",
                sub3 : "Что получает клиент",
                sub4 : "Опытная команда управления капиталом с индивидуальным управлением счетом",
                sub5 : "Полное раскрытие всех сборов и расходов",
                sub6 : "Налоговая эффективность за счет проактивного управления прибылями и убытками",
                sub7 : "Ежеквартальная и годовая отчетность в простом и понятном формате",
                sub8 : "Раскрытие информации: *По состоянию на 01.01.2022**Информация предоставляется по запросу. Прошлые показатели не свидетельствуют о будущих результатах. Этот материал не является финансовым советом или предложением продать какой-либо продукт. Фактические характеристики в отношении любой конкретной учетной записи клиента будут варьироваться в зависимости от ряда факторов, включая, помимо прочего: размер учетной записи; инвестиционные ограничения, применимые к учетной записи, если таковые имеются; и требования рынка на момент инвестирования. Консультант по капитальным инвестициям оставляет за собой право изменять свои текущие инвестиционные стратегии и методы в зависимости от динамики рынка или потребностей клиентов. Консультант по капитальным инвестициям является зарегистрированным консультантом по инвестициям.",

            },
            administrators : {
                sub : "Компания Hillhouse Capital Retirement Plan Services предлагает креативные концепции пенсионного планирования и полного администрирования льгот для всех работодателей.",
                text : "Сегодня пенсионные планы являются неотъемлемой частью общей картины компенсаций любого бизнеса. Их стоимость, сложность и вариации создали спрос на специалистов для их проектирования и установки. Разрабатывать такие программы вокруг отсрочки дохода на льготной налоговой основе мы предлагаем. Опыт профессионального персонала ориентирован на постоянные изменения в налоговом законодательстве, инвестициях и потребностях работодателя и сотрудников.",
                title : "Наши услуги включают в себя:",
                text1 : "Дизайн и соответствие.",
                text11 : "Мы изучаем объем всех типов доступных планов и координируем свои действия с управленческими, юридическими и налоговыми консультантами при выборе наилучшего плана или комбинации доступных планов.",
                text2 : "Квалификация и установка.",
                text22 : "Мы предоставляем все необходимые плановые документы и описания через наши собственные утвержденные на национальном уровне прототипы.",
                text3 : "Общение сотрудников.",
                text33 : "Наши консультанты обучены правильно зачислять и обучать ваших сотрудников пенсионной программе.",
                text4 : "Финансирование.",
                text44 : "Мы предоставляем анализ и рекомендации по всем инвестиционным продуктам через наши дочерние инвестиционные компании.",
                text5 : "Управление годовым планом.",
                text55 : "Мы предоставляем комплексные административные услуги, направленные на минимизацию затрат времени и средств на компанию и ее сотрудников.",
                types : "Типы планов:",
                item1 : "401(k) Наличные или отложенные планы",
                item2 : "Планы распределения прибыли на основе возраста",
                item3 : "Планы целевых выплат",
                item4 : "Простые планы",
                item5 : "Планы распределения прибыли",
                item6 : "Пенсионные планы покупки денег",
                item7 : "Сопоставимые планы",
                item8 : "Планы безопасной гавани",
                textb : "Миссия Hillhouse Capital's Retirement Plan Services заключается в разработке и управлении пенсионной программой, отвечающей вашим потребностям и целям, с качественным обслуживанием, высокой доходностью инвестиций и эффективными затратами.",
            },
            trustservice : {
                sub : "Выбор подходящей трастовой компании или доверительного управляющего – непростая задача. В Hillhouse Capital Investment Company (HCIC) мы понимаем важность предоставления полных фидуциарных услуг из одного источника, не ставя под угрозу услуги или инвестиционные консультации, которые мы предлагаем нашим клиентам. Мы используем сеть доверия Pershing для удовлетворения потребностей наших клиентов в доверии.",
                title : "First Carolina",
                text1 : "First Carolina Bank с гордостью предлагает услуги по управлению активами через нашего стратегического партнера Capital Investment Companies (CIC). CIC, через свои брокерско-дилерские и инвестиционные консалтинговые фирмы, может предложить нашим клиентам особую философию управления, создавая индивидуальные решения, разработанные для достижения ваших конкретных финансовых целей. У вас будет доступ к разнообразному выбору инвестиционных и финансовых услуг для каждой цели и этапа вашей жизни.",
                title1 : "ДОВЕРИТЕЛЬНАЯ СЕТЬ PERSHING",
                text2 : "Доверительная сеть Pershing была создана для удовлетворения индивидуальных и институциональных потребностей в более высоком уровне персонального обслуживания и предоставления широкого выбора высококачественных инвестиций в доверительной среде. CIC концентрирует свои ресурсы на своем основном бизнесе — удовлетворении ваших инвестиционных потребностей.",
                text3 : "Независимо от того, ищете ли вы фидуциарные услуги для своей компании или для себя, мы найдем время, чтобы помочь вам разобраться во многих сложных вопросах и правилах, поработать с вами над стратегическим планированием, рассмотреть все варианты и помочь вам сделать продуманный, но иногда трудные решения. Наша команда также может предоставить творческие решения для удовлетворения потребностей сотрудников вашей компании.",
                text4 : "Предоставление качественных услуг начинается с людей, но оно также должно включать проверенные операционные возможности. Pershing's Trust Network располагает опытным персоналом, а также современными технологиями, необходимыми для разумного и своевременного управления вашими фидуциарными делами.",
                text5 : "Многие состоятельные клиенты хотят оставить значимое наследие будущим поколениям и благотворительным организациям. Для этого они могут создавать трасты, чтобы помочь им эффективно передавать богатство, обеспечивать выполнение их желаний, минимизировать налоги на наследство и избегать завещания.",
                text6 : "Независимо от того, создают ли клиенты Pershing траст или являются его бенефициарами, через сеть Pershing Trust Network наши клиенты имеют доступ к доверительным услугам от некоторых из наиболее уважаемых трастовых компаний страны. Клиенты могут получить доступ к полному меню решений, которые стремятся удовлетворить практически любые личные потребности в доверии, полагаясь при этом на Capital в управлении своими активами.",
                title2 : "HCIC с Pershing's Trust Network предлагает вам следующие преимущества:",
                item1 : "Опытная команда с доказанным успехом",
                item2 : "Личное и объективное руководство по инвестициям",
                item3 : "Индивидуальные административные услуги",
                item4 : "Неограниченное меню вариантов инвестиций",
                title3 : "В достижении этой цели нам помогают следующие функции сервиса:",
                item5 : "Персональная инвестиционная стратегия, построенная вокруг каждого клиента;",
                item6 : "Широкая диверсификация по классам и стилям активов;",
                item7 : "Доступ к некоторым из самых талантливых управляющих капиталом в мире;",
                item8 : "постоянный мониторинг портфеля;",
                item9 : "Подробные выписки со счетов и отчеты об эффективности;",
                item10 : "Постоянная коммуникация с клиентами и технологическая поддержка",
                textb1 : "HCIC вместе с Pershing's Trust Network предлагает вам уникальный дуэт профессиональной объективности, гибкости, опыта и добросовестности, не имеющий себе равных на современном финансовом рынке.",
                textb2 : "Позвольте HCIC и Pershing's Trust Network успешно помочь вам в достижении ваших долгосрочных финансовых целей и задач.",

            },
            capitalbankpartners : {
                sub : "Hillhouse Capital Bank Partners предлагает инвестиционные программы с полным спектром услуг местным банкам и их клиентам.",
                title1 : "Банковское дело и инвестиции: мощная комбинация",
                title2 : "Доставка и управление программой.",
                text1 : "С 1984 года Hillhouse Capital Investment Group, Inc. предоставляет широкий спектр инвестиционных продуктов и услуг как предприятиям, так и частным лицам. Наши программы инвестиционных услуг для местных банков позволяют банковским учреждениям эффективно «преодолеть разрыв» между банковским обслуживанием и инвестициями для клиентов банка. Удовлетворение всех финансовых потребностей клиентов в одном месте дает два важных преимущества. Во-первых, он предоставляет клиентам простой способ объединить свои банковские и инвестиционные потребности с учреждением, которому они доверяют. Во-вторых, это позволяет банкам улучшать и расширять отношения с клиентами, одновременно увеличивая комиссионные доходы, коэффициенты перекрестных продаж и маркетинговые навыки сотрудников.",
                text2 : "В Hillhouse мы понимаем требования, предъявляемые к вашему руководству и сотрудникам современной быстро меняющейся и постоянно меняющейся банковской средой. Имея это в виду, мы предлагаем программу инвестиционных услуг «под ключ», которая поможет вам от первоначальной структуры программы до набора и управления продажами вашего инвестиционного представителя. Мы стремимся предоставить наиболее полную и подробную поддержку в отрасли. Ваш инвестиционный представитель сможет предложить вашим клиентам набор непревзойденных инвестиционных продуктов и услуг, используя самые современные технологии для доступа к счетам и управления ими. Наша цель — предоставить вам программу инвестиционных услуг, конкурентоспособную по сравнению с другими банками, брокерами и поставщиками финансовых услуг. В результате вашим клиентам больше не придется обращаться к другим учреждениям за помощью в удовлетворении своих финансовых потребностей.",
            },
            documents : {
                but : "ОТКРЫТЬ",
                title : "ДОКУМЕНТЫ",
            },
            resources : {
                title : "ИНСТРУМЕНТЫ И ССЫЛКИ, КОТОРЫЕ ВАМ ПОМОГУТ!",
                title1 : "Калькуляторы:",
                title2 : "Другие ссылки:",
                link1 : "Полный список из более чем 50 полезных калькуляторов",
                link2 : "72 (т) / Снятие пенсионного счета",
                link3 : "529 Анализатор расходов",
                link4 : "Калькулятор стоимости образования",
                link5 : "Калькулятор резервного фонда",
                link6 : "Финансовые сайты",
                link7 : "Институт финансовых услуг",
                link8 : "Ипотечный калькулятор",
                link9 : "Инструмент контрольной точки взаимных фондов",
                link10 : "Инструмент поиска точки останова взаимных фондов",
                link11 : "Анализатор расходов взаимных фондов и ETF",
                link12 : "Анализатор расходов взаимных фондов",
                link13 : "Калькулятор расходов взаимных фондов",
                link14 : "Калькулятор опционов",
                link15 : "Калькулятор Рота",
                link16 : "Калькулятор сбережений",
                link17 : "Калькулятор переменной ренты",
                link18 : "Финансовый глоссарий",
                descr1 : "Рассчитывает ваши будущие расходы на образование и сколько вам нужно сэкономить сейчас, чтобы покрыть эти расходы.",
                descr2 : "Рассчитывает, сколько вы должны накопить, чтобы быть готовым к маленьким сюрпризам жизни.",
                descr3 : "Рассчитывает ваш ежемесячный платеж или сумму кредита и отображает таблицу в годовом исчислении.",
                descr4 : "Рассчитывает, сколько вам нужно накопить сейчас, чтобы выйти на пенсию миллионером.",
                descr5 : "Более 6 000 записей с 15 000 ссылок, составленных профессором Университета Дьюка Кэмпбеллом Р. Харви.",

            },
            joinCapital : {
                title : "ПРИСОЕДИНЯЙТЕСЬ К НАШЕЙ КОМАНДЕ",
                but1 : "Присоединиться",
                but2 : "Причины",
                p1 : "Hillhouse Capital — одна из ведущих независимых брокерско-дилерских компаний Юго-Востока. Мы предлагаем все ресурсы крупной корпоративной фирмы для поддержки наших независимых представителей, но именно наша индивидуальная поддержка дает нам «чувство семьи». Эта приверженность личной заботе принесла нашей компании лояльную команду брокеров и последователей, число которых растет с каждым днем.",
                p2 : "Благодаря новейшим технологиям, обширным решениям по управлению состоянием, конкурентоспособным комиссиям, дружественному брокеру соблюдению требований, мы предлагаем все: от традиционных брокерских услуг до управления капиталом, планирования недвижимости, трастовых услуг и комплексных страховых решений, дополняющих целостный стиль финансового планирования. Наша практическая помощь делает нас вашей «командой успеха».",
                p3 : "Если вы ищете место, где вы сможете преодолеть бюрократическую волокиту и достичь личного успеха — в качестве независимого представителя или инвестора",
                link : "Вступить домой в HILLHOUSE!",
                em : " тогда вы должны",
            },
            courses : {
                title : "Наши пакеты",
                item1 : "электронные книги",
                item2 : "образовательные материалы",
                item3: "продвинутое ПО для построения графиков",
                item4 : "новости рынка",
                item5 : "видеоуроки",
                item6 : "ежедневные торговые сигналы",
                item7 : "экономический календарь",
                no : "без консультации",
                yes : "консультация включена",
                button: "Получить",
            },
            reasons : {
                title : "ПРИЧИНЫ",
                sub: "ПРИЧИНЫ, ПОЧЕМУ ВЫ ДОЛЖНЫ ВЫБРАТЬ ИНВЕСТИЦИОННУЮ КОМПАНИЮ HILLHOUSE ВАШИМ БРОКЕРОМ / ДИЛЕРОМ...",
                 list1 : "Высокие комиссионные выплаты с минимальным количеством точек излома.",
                 list2 : "Комплексное страхование E & O по разумной цене с вычитаемым полисом перестрахования.",
                 list3 : "Комплексные услуги по страхованию и планированию имущества с высокими выплатами.",
                 list4 : "Эффективные, быстрые, торговые и клиринговые услуги, предоставляемые Pershing.",
                 list5 : "Профессиональный, вежливый персонал что приводит к минимальным проблемам и быстрым решением.",
                 list6 : "Вы сами определяете комиссионные ставки клиентов, от скидки до полного обслуживания.",
                 list7 : "Полные торговые услуги с фиксированным доходом через Pershing.",
                 list8 : "100 миллионов долларов США на защиту брокерского счета клиента с Pershing.",
                 list9 : "Управление денежными средствами (CMA) и БЕСПЛАТНЫЕ услуги проверки в Pershing..",
                 list10 : "Ежемесячные звонки по продажам, посвященные продажам и маркетингу.",
                 list11 : "Возрождение национальных конференций по продажам с непрерывным образованием.",
                 list12 : "\"Обучение продажам в полевых условиях\" и наща помощь в этом. ",
                 list13 : "Помощь в управлении практикой.",
                 list14 : "Внутренняя профессиональная комплексная проверка, дополненная внешним консультантом.",
                 list15 : "Комплексная альтернативная инвестиционная платформа",
                 list16 : "Учебные курсы для вашего вспомогательного персонала на дому или в филиале.",
                 list17 : "Быстрая передача вашей лицензии на ценные бумаги, если вы имеете право на \"TAT\".",
                 list18 : "48-часовая проверка соответствия требованиям для семинаров, радиопередач, статей.",
                 list19 : "24-часовое утверждение соответствия на визитных карточках, канцелярских товарах и вывесках.",
                 list20: "Комплексная технологическая платформа передового опыта, включающая мониторинг портфеля, отчеты о рисках или распределении активов, управление практикой, финансовое планирование, эффективность портфеля и сводные отчеты с Albridge»",
                 list21 : "Программы управления капиталом, предлагающие различные признанные на национальном уровне менеджеры.",
                 list22 : "Платформа, состоящая из семи различных компаний/подразделений для кросс-рынка и развития вашего бизнеса.",
                // list2
                list23 : "Hillhouse Capital Insurance Affiliates — страховое подразделение с полным спектром услуг, специализирующееся на планировании недвижимости.",
                list24 : "Hillhouse Capital Advisors — программа распределения активов с использованием взаимных фондов без нагрузки, средств с нагрузкой в NAV, ETF и отдельных менеджеров по работе с клиентами.",
                list25 : "Hillhouse Capital Mortgage Associates — помогает вашим клиентам финансировать или рефинансировать свои дома.",
                list26 : "The Hillhouse Capital Foundation — организация, которая решает индивидуальные благотворительные задачи, пользуясь финансовыми преимуществами статуса освобождения от налогов.",
                list27 : "Консультационные услуги по капитальным инвестициям - комплексная платформа «управляющий» с недорогими моделями для удовлетворения потребностей ваших клиентов.",
                list28 : "Capital's Trust Services - фидуциарные услуги из одного источника для удовлетворения любых потребностей ваших клиентов в планировании недвижимости.",
                list29 : "Capital Bank Partners - мы помогаем клиентам наших банков преодолеть разрыв между банковским делом и инвестициями.",

            },
            compliance : {
                title : "Резюме взаимоотношений с клиентами для наших организаций",
                sub : "Сводные данные о взаимоотношениях с клиентами для наших организаций (форма CRS)",
                bussines : "Уведомление о непрерывности бизнеса",
                text1 : "В случае серьезного внутреннего или внешнего сбоя в бизнесе наша фирма разработала план обеспечения непрерывности бизнеса, в котором указано, как мы будем реагировать на события, которые серьезно нарушают наш бизнес. Для вашего удобства мы включили копию нашего Уведомления о плане обеспечения непрерывности бизнеса.",
                text2 : "Правило SEC 11AC1-6 SEC приняла правила для улучшения публичного раскрытия практики исполнения и маршрутизации ордеров. В соответствии с правилом 11AC1-6,Hillhouse Capital Investment Group, Inc. и Hillhouse Capital Investment Brokerage, Inc. отчеты, в которых, среди прочего, указываются места, куда направляются заказы клиентов для исполнения. Кроме того,Hillhouse Capital Investment Group, Inc. и Hillhouse Capital Investment Brokerage, Inc. их индивидуальные ордера были отклонены.Показывая качество исполнения на рынках ценных бумаг, правила призваны стимулировать более активную конкуренцию среди участников рынка, чтобы обеспечить наилучшие цены для ордеров инвесторов.",
                text3 : "Hillhouse Capital Investment Group, Inc. и Hillhouse Capital Investment Brokerage, Inc. направляют все приемлемые внебиржевые и листинговые ордера непосредственно в нашу клиринговую фирму Pershing LLC для исполнения.",
                text4 : "Чтобы получить статистическую информацию о маршрутизации заказов, щелкните вкладку «Поиск» в верхней части страницы на следующем сайте.",
                text5 : "Комиссия по ценным бумагам и биржам требует, чтобы все брокерские фирмы информировали своих клиентов о том, получают ли такие фирмы оплату за поток ордеров.Hillhouse Capital Investment Group, Inc. и Hillhouse Capital Investment Brokerage, Inc. таких выплат не получают. Поток заказов относится к процессу, с помощью которого выполняются ваши заказы. Стремясь выполнить ордер клиента на наилучшем доступном рынке, брокерская фирма может выполнить ордер в качестве принципала или может направить ордер аффилированному или неаффилированному брокеру-дилеру или биржевому специалисту для исполнения.",
                title1 : "Порядок маршрутизации",
                title2 : "Оплата потока заказов",
            }
        }
    }
}